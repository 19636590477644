$(function(){
    // ACCORDION
    $('.acc .acc-head').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('.acc-info').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('.acc-info').stop().slideToggle();
    });

    // ACCORDION
    $('.acc header').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('footer').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('footer').stop().slideToggle();
    });


    // ACCORDION Toggle 2
    $('.listBlock a.cta').click(function(){
        var parent = $(this).parent();
        parent.find('.content .details').slideToggle();
    });


    // Equalize product block heights
    $('.productBlocks, .gridBlocks').imagesLoaded(function(){
        $(window).resize(function(){
            equalize($('.productBlocks:not(.gridBlocks):not(.threeBlock)'), $('.product') );
            equalize($('.gridBlocks:not(.gridBlocks):not(.threeBlock)'), $('.item') );
        }).resize();
    });


    // If prevous page is 
    $('.previousPage').each(function(){
        if (document.referrer == window.location.origin) {
            $(this).show();
            $(this).click(function(event){
                event.preventDefault();
                window.history.back();
            });
        } else {
            $(this).hide();
        }
    });

    $( "#datepicker" ).datepicker();
});


// Font Size Increase & Decrease
$(document).ready(function(){
   var originalSize = $('div').css('font-size');
  // reset
   $(".resetMe").click(function(){
      $('div').css('font-size', originalSize); 

    });

   // Increase Font Size
   $(".increase").click(function(){
  var currentSize = $('div, p, ul li').css('font-size');
  var currentSize = parseFloat(currentSize)*1.2;
  $('div, p, ul li').css('font-size', currentSize);

  return false;
   });

   // Decrease Font Size
   $(".decrease").click(function(){
  var currentFontSize = $('div, p, ul li').css('font-size');
  var currentSize = $('div, p, ul li').css('font-size');
  var currentSize = parseFloat(currentSize)*0.8;
  $('div, p, ul li').css('font-size', currentSize);

  return false;
   });
});




// Latest NEW Slider
$(function() {
    var mainCarousel = $('div.ticker');

    mainCarousel.imagesLoaded(function(){
        mainCarousel.flickity({
            cellAlign: 'center',
            groupCells: true,
            contain: true,
            prevNextButtons: true,
            pageDots: false,
            autoPlay: false,
            wrapAround: true,
            arrowShape: { 
                x0: 20,
                x1: 55, y1: 50,
                x2: 60, y2: 50,
                x3: 25
            }
        });
    });

});